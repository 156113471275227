<template>
  <v-container fluid>
    <BaseTable
      ref="table"
      title="Elenco link"
      module="navEntries"
      :headers="headers"
      :actions="actions"
      sortby="text"
    >
      <template v-slot:actions>
        <v-btn
          v-if="canUser('nav_entries', 'create')"
          color="green"
          dark
          @click="openModal"
        >
          Crea link
        </v-btn>
      </template>

      <template v-slot:item.preview="{ item }">
        <NavEntry v-bind="item" href="#" target="_top" />
      </template>
    </BaseTable>
    <v-dialog
      v-model="dialog"
      max-width="75%"
      :retain-focus="false"
      @click:outside="modalClose"
    >
      <v-card>
        <v-container fluid>
          <LinkForm :edit="editMode" @submitted="onSubmitted" />
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import BaseTable from '@components/structure/base-table.vue'
import { authComputed } from '@state/helpers.js'
import { mapActions, mapMutations } from 'vuex'
import LinkForm from '@components/nav_entries/LinkForm.vue'
import NavEntry from '@/src/components/nav_entries/NavEntry.vue'

export default {
  name: 'CustomersSearch',
  page: {
    title: 'Clienti',
  },
  components: { BaseTable, LinkForm, NavEntry },
  data() {
    return {
      dialog: false,
      editMode: false,
      headers: [
        {
          text: 'Nome',
          value: 'text',
        },
        {
          text: 'Link',
          value: 'href',
        },
        ...(this.$vuetify.breakpoint.mdAndUp
          ? [
              {
                text: 'Anteprima',
                value: 'preview',
                width: 250,
              },
            ]
          : []),
      ],
      actions: [
        {
          label: 'Modifica',
          icon: 'mdi-pencil',
          color: 'primary',
          handler: this.openModal,
          onItemCondition: () => this.canUser('nav_entries', 'update'),
        },
      ],
    }
  },
  computed: {
    ...authComputed,
  },
  methods: {
    ...mapActions('navEntries', ['removeItem', 'getDropdownList']),
    ...mapMutations('navEntries', {
      setCurrent: 'SET_CURRENT',
      resetCurrent: 'RESET_CURRENT',
    }),
    openModal(item) {
      const startingPoint = item.id ? item : {}
      // Understand if the modal was opened as editing or for a new sub
      const isEditing = !!startingPoint.id
      this.editMode = isEditing
      this.setCurrent(startingPoint)
      this.dialog = true
    },
    async onSubmitted() {
      this.modalClose()
      this.$refs.table.refresh()
      this.getDropdownList()
    },
    modalClose() {
      this.dialog = false
      this.editMode = false
      this.resetCurrent()
    },
  },
}
</script>
