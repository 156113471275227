<template>
  <v-list-item :href="href" :disabled="disabled" :target="target">
    <v-list-item-icon class="d-flex align-items-cenetr justify-center">
      <v-img
        v-if="image"
        :lazy-src="image"
        height="27"
        width="27"
        :src="image"
        style="border-radius: 50px; transform: scale(1.1)"
      ></v-img>
      <v-icon v-else style="transform: scale(1.1); margin-left: 3px"
        >mdi-link-box-outline</v-icon
      >
    </v-list-item-icon>
    <v-list-item-content class="body-2">
      {{ text }}
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'NavEntry',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      required: true,
    },
    href: {
      type: String,
      required: true,
    },
    image: {
      type: String,
    },
    target: {
      type: String,
      required: true,
    },
  },
}
</script>
