<template>
  <FormItem
    v-model="id"
    type="select"
    :values="list"
    :name="name"
    :rules="rules"
    :hint="hint"
    :cols="cols"
    :sm="sm"
    :md="md"
    :lg="lg"
    :xl="xl"
    :dense="dense"
    :disabled="disabled"
    :clearable="clearable"
  />
</template>

<script>
import FormItem from '@components/forms/FormItem.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CustomersLocationsSelect',
  components: {
    FormItem,
  },
  props: {
    customerid: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Array],
      default: null,
    },
    cols: {
      type: [String, Number],
      default: 12,
    },
    sm: {
      type: [String, Number],
      default: 6,
    },
    md: {
      type: [String, Number],
      default: 3,
    },
    lg: {
      type: [String, Number],
      default: 3,
    },
    xl: {
      type: [String, Number],
      default: 2,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: 'Sede',
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    disabled: {
      type: Boolean,
    },
    autoSelect: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    id: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      },
    },
    ...mapGetters('customersLocations', {
      list: 'getListDropdown',
    }),
  },
  watch: {
    customerid: {
      immediate: true,
      handler: 'populate',
    },
    list: {
      immediate: true,
      handler: 'onMounted',
    },
  },
  mounted() {
    this.populate()
  },
  methods: {
    ...mapActions('customersLocations', ['getDropdownList']),
    populate() {
      if (this.customerid) {
        this.getDropdownList({
          filters: {
            customer_id: this.customerid,
          },
        })
      }
    },
    onMounted() {
      if (this.autoSelect && this.list.length === 1) {
        this.id = this.list[0].value
      }
    },
  },
}
</script>
